import { Col, Row } from "react-bootstrap";
import SubPages from "../components/SubPages";
import KoalaTee from "../assets/img/koalaTee.png";

export default function Koala() {
  return (
    <SubPages title="Trans*-Gruppe „koalaTee“">
      <Row>
        <Col md={4}>
          <img
            className="news"
            src={KoalaTee}
            alt="Logo koalaTee von Queer in Niederbayern e.V."
          />
        </Col>
        <Col md={8}>
          <p>
            Die Gruppe koalaTee ist eine niederbayernweite Anlaufstelle für
            trans*, inter* und nicht-binäre Menschen aller Altersgruppen. Wir
            heißen auch Angehörige und Bezugspersonen von trans*, inter* und
            nicht-binären Menschen willkommen. Die Gruppe dient vor allem dem
            Austausch untereinander sowie um über die Lebensrealität von trans*,
            inter* und nicht-binären Menschen zu sprechen.
          </p>
        </Col>
      </Row>
      <hr />
      <Row className="padtopbottom2rem">
        <Col md={{ span: 8, offset: 2 }}>
          <p>
            Wir machen u. a. Ausflüge, spielen oder backen und kochen. Manchmal
            treffen wir uns auch mit anderen Gruppen.<br></br>
            Wir treffen uns einmal im Monat, immer am dritten Samstag ab 15:00
            Uhr. Derzeit treffen wir uns ausschließlich in Landshut in der
            Beratungsstelle up2you. Wir sind aber auf der Suche nach neuen
            Menschen in der Orga, um auch an anderen Orten in Niederbayern
            wieder Treffen anzubieten.<br></br>
            Wenn du Fragen hast oder mit uns in Kontakt treten willst, schreib
            doch gerne eine Mail an{" "}
            <a
              className="d-orange"
              href="mailto:koalatee@queer-niederbayern.de"
            >
              koalatee@queer-niederbayern.de
            </a>
            .
          </p>
        </Col>
      </Row>
      <hr />
      <Row className="padtopbottom2rem">
        <p>
          Möchtest du mit uns in Kontakt treten oder mal bei uns vorbeischauen?
          Dann melde dich einfach bei uns.
        </p>
        <p>Die Gruppenleitung haben Matze und Katharinax</p>
      </Row>
    </SubPages>
  );
}
