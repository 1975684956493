import { Col, Row } from "react-bootstrap";
import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";
import Strong from "../assets/img/STRONG-Logo-nosubline.svg";
import Lambda from "../assets/img/lambda-logo.png";
import Respect from "../assets/img/respect.gif";
import CSD from "../assets/img/Logo-CSD-Deutschland.png";
import LSVD from "../assets/img/LogoLSVD.png";
import Ois from "../assets/img/ois.gmachtin.bayern-R.png";
import Podcast from "../assets/img/queer-podcast.png";
import Rewe from "../assets/img/REWE.jpg";
import Rewe_new from "../assets/img/REWE_Negativ.png";
import WeAreStraubing from "../assets/img/wir-sind-straubingen.png";
import HassStecker from "../assets/img/hass-stecker.png";
import Traum from "../assets/img/traumerfuellerin.png";
import IGMetall from "../assets/img/IGMetall-Logo.png";
import DGB from "../assets/img/DGB.png";
import Ballon from "../assets/img/ballonwerkstatt.jpg";
import Adito from "../assets/img/LogoADITO.png";
import IWWIT from "../assets/img/iwwit.png";
import BMW from "../assets/img/bmw.png";
import TextAndPic from "../components/UI/TextAndPic";

const subtitle = (
  <p>
    Hier für euch zusammengefasst: Alle wichtigen Links zu unseren Sponsoren,
    Mitwirkenden oder auch zu Studien, die sich mit dem Thema Queer
    beschäftigen. Ihr habt eine Idee, was hier noch ergänzt werden könnte? Dann
    benutzt gern unser{" "}
    <a className="d-pink" href="/contact">
      Kontaktformular
    </a>{" "}
    oder schreibe direkt an{" "}
    <a className="d-pink" href="mailto:kontakt@queer-niederbayern.de">
      kontakt@queer-niederbayern.de
    </a>
    , damit wir uns schnell uns effektiv vernetzen können.
  </p>
);

export default function Links() {
  const extraOis = <strong className="d-pink">BUNT!</strong>;
  return (
    <SubPages title="Links" subtitle={subtitle}>
      <RowColFull className="padtop2rem">
        <h3 className="d-pink">Mitwirkende und Unterstützer</h3>
      </RowColFull>
      <TextAndPic
        link="https://www.rewe-group.com/de/unternehmen/unternehmenskultur/diversity/"
        linkText="REWE Group"
        text="steht als Abbild einer bunten Welt. Und das ist gut so. Denn für sie ist klar: Erfolg braucht Menschen, die unterschiedlich ticken. Vielfalt schafft Kreativität – und es sind diese unterschiedlichen Perspektiven und Erfahrungen, die uns gemeinsam voranbringen."
        src={Rewe_new}
      />
      <TextAndPic
        link="https://strong-community.de/"
        linkText="STRONG!"
        text="registriert ausschließlich homo- und transphobe und alle
      queerfeindlichen Straftaten. Darüber hinaus bietet STRONG! auch
      psychologische Beratung und Unterstützung an."
        className="lambda"
        src={Strong}
      />
      <TextAndPic
        link="https://www.lambda-bayern.de/"
        linkText="Lambda Bayern e.V."
        text="- Eine Anlaufstelle für lesbische, schwule, bisexuelle, trans*,
        inter* und queere Jugendliche in Bayern und die Dachorganisation unserer queeren Jugendgruppen."
        className="lambda"
        src={Lambda}
      />
      <TextAndPic
        link="https://www.iwwit.de/"
        linkText="IWWIT"
        text="- Wissenssammlungen rund um das Thema Safer Sex und
        Beratungsangebote."
        src={Respect}
      />
      <TextAndPic
        link="https://csd-deutschland.de/"
        linkText="CSD Deutschland e.V."
        text="- Dachorganisation aller organisierter CSD-Vereine Deutschlands und Schirmherr der deutschen CSDs"
        src={CSD}
        className="rewe"
      />
      <TextAndPic
        link="https://www.lsvd.de/"
        linkText="LSVD"
        text="- Lesben- und Schwulenverband Deutschlands ist ein Bürgerrechtsverband und vertritt die Interessen und Belange von Lesben, Schwulen, Bisexuellen, trans- und intergeschlechtlichen und queeren Menschen (LSBTIQ*). Queer in Niederbayern e. V. ist Mitglied im LSVD Bayern e. V."
        src={LSVD}
      />
      <TextAndPic
        link="https://muenchner-regenbogen-stiftung.de/"
        linkText="Münchner Regenbogenstiftung"
        text="- Eine Stiftung, die sich für die Förderung der Gleichstellung und
        Antidiskriminierung von Lesben, Schwulen und Transgendern in München
        und Umgebung einsetzt."
      />
      <TextAndPic
        link="https://ois.gmachtin.bayern/"
        linkText="OIS.gmachtin.bayern"
        text="- Eine bayerische Schatzkiste für bayerische Produkte und
        Dienstleistungen, ein Schmankerl-Eck bei Kunst und Kultur, ein
        einfach ois beim Leben und Leben lassen. Außerdem ist der Betreiber
        des Netzwerkportals häufig oder fast immer am Auslöser einer Nikon
        D850 - mit seiner besonderen Vorliebe für die schönste aller
        bayerischen Farben: "
        extra={extraOis}
        src={Ois}
        className="rewe"
      />

      <hr className="padtopbottom1rem" />

      <Row>
        <Col md={8}>
          <h3 className="d-pink">Podcasts</h3>
        </Col>
        <Col md={4}>
          <img
            className="news"
            src={Podcast}
            alt="Logo vom Podcast Willkommen im Club"
          />
        </Col>
      </Row>
      <RowColFull>
        <p>
          <a
            className="d-pink"
            href="https://www.br.de/mediathek/podcast/willkommen-im-club-der-lgbtiq-podcast-von-puls/alle/832?page=2&order=relevance"
            target="_blank"
            rel="noreferrer"
          >
            BR-Podcast "Willkommen im Club"
          </a>
          <br />
          Über welche Klischees regen sich Lesben, Schwule, Bisexuelle,
          Transgender und andere queere Menschen so richtig auf? Was ist bei
          ihnen gerade Thema und wo gibt’s Probleme? PULS, das junge
          Content-Netzwerk des Bayerischen Rundfunks, widmet sich mit dem neuen
          Podcast “Willkommen im Club” genau diesen und vielen weiteren
          drängenden Themen aus der Lebenswelt der LGBTIQ*-Community.
          <br />
          “Willkommen im Club” bespricht die Themen und Probleme queerer
          Menschen in ihrer Vielfalt und Diversität offen und auf Augenhöhe. Die
          ersten Folgen beleuchten Themen, wie: "Warum sind Outings auch 2020
          immer noch ein großer Schritt?" oder "Wie können wir mit unserer
          Sprache niemanden diskriminieren" und "Warum werden Bisexuelle oft
          nicht ernst genommen?".
          <br />
          <a
            className="d-pink"
            href="https://www.br.de/mediathek/podcast/willkommen-im-club-der-lgbtiq-podcast-von-puls/alle/832?page=2&order=relevance"
            target="_blank"
            rel="noreferrer"
          >
            "Willkommen im Club"
          </a>{" "}
          gibt es jeden Mittwoch neu bei Spotify, iTunes, Deezer, in der ARD
          Audiothek und auf BR Podcast.
        </p>
        <p>
          <a
            className="d-pink"
            href="https://www.youtube.com/hashtag/queercastathome"
            target="_blank"
            rel="noreferrer"
          >
            GOQUEER: Lambda queercast
          </a>
          <br />
          #QUEERCASTATHOME heißt es beim „lambda queercast- der queere Podcast“.
          Wir gehen mit unseren ersten Folgen onair und füllen eure
          Podcastplaylist nun jede Woche Samstag, über den Podcastfeed unseres
          Partners „GOQUEER“ mit einer neuen Folge und immer mit dabei? Jedesmal
          haben wir eine neue Gesprächsperson und jedesmal auch ein anderes
          queeres Thema.
          <br />
          Der{" "}
          <a
            className="d-pink"
            href="https://www.youtube.com/hashtag/queercastathome"
            target="_blank"
            rel="noreferrer"
          >
            lambda queercast
          </a>{" "}
          wird produziert vom Jugendnetzwerk Lambda Mitteldeutschland e.V. in
          Zusammenarbeit mit dem Medienprojekt GOQUEER.
        </p>
      </RowColFull>

      <hr className="padtopbottom1rem" />

      <RowColFull>
        <h3 className="d-pink">Studien</h3>
        <p>
          <a
            className="d-pink"
            href="https://bayern.lsvd.de/wp-content/uploads/2020/06/14-5-2020-ergebnisse_queereslebenbayern_final.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Studie
          </a>{" "}
          in Zusammenarbeit von Bündnis 90, die Grünen, und der Hochschule
          Landshut zum Thema "Queeres Leben in Bayern 2020"
        </p>
      </RowColFull>

      <hr className="padtopbottom1rem" />

      <RowColFull>
        <h3 className="d-pink">Bilder</h3>
        <p>
          Ein herzliches Dankeschön auch immer an die fleißigen Fotografen, hier
          v.a. auch für die Webseite und Werbemittel herauszustellen sind:{" "}
          <a
            className="d-pink"
            href="https://www.flickr.com/photos/16273261@N00/"
            target="_blank"
            rel="noreferrer"
          >
            Fotomacher
          </a>{" "}
          &{" "}
          <a
            className="d-pink"
            href="https://www.vrainstyle.de/"
            target="_blank"
            rel="noreferrer"
          >
            vrainstyle
          </a>
        </p>
      </RowColFull>

      <hr className="padtopbottom1rem" />

      <RowColFull>
        <h3 className="d-pink">Bisherige Sponsoren</h3>
      </RowColFull>
      <Row>
        <Col md={4} className="padtopbottom1rem">
          <img className="sponsor" src={Rewe} alt="REWE Logo" />
        </Col>
        <Col md={4} className="padtopbottom1rem">
          <img
            className="sponsor"
            src={WeAreStraubing}
            alt="Wir sind Straubing Logo"
          />
        </Col>
        <Col md={4} className="padtopbottom1rem">
          <img
            className="sponsor"
            src={HassStecker}
            alt="Dem Hass den Stecker ziehen Logo"
          />
        </Col>
      </Row>
      <div className="padtopbottom1rem"></div>
      <Row>
        <Col md={4}>
          <img className="sponsor" src={Traum} alt="Traumerfüllerin Logo" />
        </Col>
        <Col md={4}>
          <img className="sponsor" src={BMW} alt="BMW Logo" />
        </Col>
        <Col md={4}>
          <img className="sponsor" src={IGMetall} alt="IG-Metall Logo" />
        </Col>
      </Row>
      <div className="padtopbottom1rem"></div>
      <Row>
        <Col md={4}>
          <img className="sponsor" src={DGB} alt="DGB Logo" />
        </Col>
        <Col md={4}>
          <img
            className="sponsor"
            src={Ballon}
            alt="Ballonwerkstatt Landshut Logo"
          />
        </Col>
        <Col md={4}>
          <img className="sponsor" src={Adito} alt="ADITO Software GmbH Logo" />
        </Col>
      </Row>
      <div className="padtopbottom1rem"></div>
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          <img className="lambda" src={IWWIT} alt="IWWIT Logo" />
        </Col>
      </Row>
    </SubPages>
  );
}
