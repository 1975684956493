import MainPages from "../components/MainPages";
import RowColFull from "../components/UI/RowColFull";
import Logo from "../assets/img/QIN_black.png";
import Rewe from "../assets/img/REWE_Negativ.png";
import Satzung from "../assets/files/Satzung.pdf";
import Beitragsordnung from "../assets/files/Beitragsordnung.pdf";
import PicLink from "../components/UI/PicLink";

const subtitle = (
  <p>
    Der Verein "Queer in Niederbayern e.V." wurde am 18.06.2019 in Landshut
    gegründet und ist die Anlaufstelle für die LGBTIQ*-Community in
    Niederbayern. Er fördert und fordert die Akzeptanz sowie Gleichstellung der
    LGBTIQ*-Lebensweisen (engl.: lesbian, gay, bisexual, transgender, inter,
    queer) in der Öffentlichkeit und im Alltag. Niemand soll aufgrund der
    sexuellen Orientierung oder Geschlechtsidentität diskriminiert werden –
    weder rechtlich noch gesellschaftlich.
  </p>
);

function Home() {
  return (
    <MainPages
      topImage="home-img"
      bottomImage="home-img2"
      title="Queer in Niederbayern e.V."
      subtitle={subtitle}
    >
      <hr />
      <RowColFull className="padtop2rem">
        <h2 className="d-pink center">Safe the date: CSD-Termine 2024</h2>
      </RowColFull>
      <RowColFull className="padtopbottom2rem">
        <p className="center">
          5. CSD Kelheim: Samstag, 01. Juni 2024
          <br />
          5. CSD Straubing: Samstag, 27. Juli 2024
          <br />
          6. CSD Landshut: Samstag, 28. Sept. 2024
        </p>
        <p className="small center">
          CSDs sind für uns finanziell auch immer anspruchsvoll. Allein eine
          Bühne mit Ton- und Lichttechnik verursachen Kosten von rund 5.000
          Euro. <br />
          Du möchtest uns unterstützen? Sehr gerne kannst du uns deine Spende
          gerne per Überweisung zukommen lassen.
          <br />
          <br />
          Queer in Ndb. e. V.
          <br />
          IBAN: DE09 7435 0000 0020 9682 56
          <br />
          Sparkasse Landshut
          <br />
          Verwendungszweck: Spende
        </p>
      </RowColFull>
      <RowColFull>
        <PicLink
          link="https://www.rewe-group.com/de/unternehmen/unternehmenskultur/diversity/"
          src={Rewe}
          className="sponsor"
          alt="Rewe Logo verlinkt zu Rewe diversity"
        />
      </RowColFull>
      <hr />
      <RowColFull>
        <img
          className="qinstart"
          alt="Queer in Niederbayern e.V. Logo"
          src={Logo}
        />
      </RowColFull>
      <RowColFull className="padbottom2rem">
        <h2 className="d-pink center">Wie kam es zu diesem Verein?</h2>
      </RowColFull>

      <RowColFull>
        <p>
          Ein kleiner Kreis von 8 - 15 politisch engagierter Menschen
          unterschiedlichen Alters haben sich mit dem Ziel zusammengetan, in
          Landshut einen ersten Christopher-Street-Day zu veranstalten. Bei
          verschiedenen Vorbereitungstreffen ist man schnelle übereingekommen,
          dass dies kein Einmalevent werden darf und nicht auf Landshut begrenzt
          sein soll. Beim weiteren „brainstormen“ war schnell klar, dass es
          mehr, als nur die Organisation eines CSD werden soll. Schnell war man
          sich einig, dass man einen überparteilichen, gemeinnützigen Verein
          auch als Anlaufstelle für queere Menschen in Niederbayern gründen
          muss. Im weiteren Verlauf wurde die Gründung des Vereins vorbereitet.
          Die vielen Ziele des Vereins lest ihr bitte in der{" "}
          <a
            href="./"
            className="d-pink"
            target="_blank"
            onClick={() => window.open(Satzung)}
          >
            Satzung
          </a>
          , §2 - Zweck, oder hier unter{" "}
          <a className="d-pink" href="goals">
            Ziele
          </a>{" "}
          nach. Vielen Dank.
        </p>
        <p>
          Am 18. Juni 2019 war es dann soweit, die Gründungsversammlung im
          Landshuter Gasthaus "Weißes Bräuhaus Zum Krenkl" stand an. Und für die
          Organisatoren völlig überraschend kamen über 70 Menschen aus fast ganz
          Niederbayern zur Gründungsversammlung. Im völlig überfüllten
          Nebenzimmer wurden spontan 55 Menschen direkt Gründungsmitglied. In
          der Gründungsversammlung wurden nach intensiver Diskussion die Satzung
          und die{" "}
          <a
            href="./"
            className="d-pink"
            target="_blank"
            onClick={() => window.open(Beitragsordnung)}
          >
            Beitragsordnung
          </a>{" "}
          beschlossen, sowie der erste 14-köpfige Vorstand mit einer
          Doppelspitze gewählt.
        </p>
        <p>
          Die erste Aufgabe des neuen Vereins war dann die Organisation des 1.
          CSD Niederbayern am Samstag, den 24. Sept. 2019 in Landshut. Dazu
          hatte man dann gerade einmal 87 Tage Zeit. Und was soll man sagen? Aus
          dem Stand der drittgrößte CSD 2019 in Bayern, nach München und
          Nürnberg. In Landshut hatten wir 2.500 Teilnehmende am
          Demonstrationszug (die größte Demo die Landshut bis dahin je gesehen
          hat) und 5.000 Teilnehmende und Besuchende beim regenbogenbunten
          Straßenfest mit Bühnenprogramm.
        </p>

        {/* 

        <p>
          Wir freuen uns über Jede*n, die*der uns in unserer vielfältigen Arbeit
          und/oder bei der Organisation und der Durchführung unserer
          Christopher-Street-Days helfen und unterstützen möchte. Meldet euch
          einfach bei uns unter{" "}
          <a className="d-pink" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
          .
        </p>

        <p>
          Und wer unsere Arbeit oder die Organisation unserer niederbayerischen
          Christopher-Street-Days finanziell unterstützen möchte, kann uns gerne
          Spenden zukommen lassen.
          <br />
          Geldinstitut: Sparkasse Landshut
          <br />
          Kontoinhaber: Queer in Niederbayern e. V.
          <br />
          IBAN: DE09 7435 0000 0020 9682 56
          <br />
          BIC: BYLADEM1LAH
        </p>

        <p>
          Und da wir als gemeinnützige Organisation vom Finanzamt anerkannt
          sind, erhalten die Spender*innen auch Spendenbescheinigungen. Bei
          Spenden bis 300 Euro genügt auch der Kontoauszug mit dem
          Verwendungszeck „Spende“. Aber auf Wunsch erhalten auch Spende*innen
          für Beträge unter 300 Euro gerne eine Spendenbescheinigung.
        </p> */}
      </RowColFull>
    </MainPages>
  );
}

export default Home;
