import NewsItem from "../components/NewsItem";
import SubPages from "../components/SubPages";
import PrEP from "../assets/img/prep.png";
import TextAndPic from "../components/UI/TextAndPic";

const color = "d-blue";
const subtitle = (
  <p>
    Alle Neuigkeiten rund um den Verein Queer Niederbayern e.V. findest du
    natürlich auf{" "}
    <a
      className={color}
      href="https://www.facebook.com/queerniederbayern/"
      target="_blank"
      rel="noreferrer"
    >
      Facebook
    </a>{" "}
    und{" "}
    <a
      className={color}
      href="https://www.instagram.com/queer_niederbayern/"
      target="_blank"
      rel="noreferrer"
    >
      Instagram
    </a>
    . Hier fassen wir für dich unsere wichtigsten Ereignisse zusammen!
  </p>
);

export default function News() {
  return (
    <SubPages title="News" subtitle={subtitle}>
      <NewsItem title="Lieferengpass von PrEP">
        <TextAndPic
          link="https://www.iwwit.de/prep-engpass"
          linkText="PrEP Lieferengpass"
          src={PrEP}
          color="d-blue"
          className="rewe"
          text="- Die Präexpositionsprophylaxe (PrEP) schützt vor HIV. Nun ist das Medikament aufgrund von Lieferengpässen nur noch schwer zu bekommen. Informationen dazu was PrEP-Nutzer*innen jetzt tun können findet ihr im Link."
        />
      </NewsItem>
      <NewsItem title="Tätlicher Übergriff auf schwules Paar">
        <p className="mid">
          Landshut/Dingolfing. Nach dem Besuch der ersten queeren Party in einem
          Club in Dingolfing wurde ein junges, schwules Paar aus dem Landkreis
          Landshut queerfeindlich angegriffen. Nach dem die beiden jungen Männer
          in der Nacht von Freitag auf Samstag den Club verlassen hatten, wurde
          sie von einer Gruppe junger Männer, augenscheinlich mit
          Migrationshintergrund, angepöbelt. Dabei beschimpfte ein junger Mann
          aus der Gruppe das schwule Paar als "Schwuchteln". Anschließend wird
          einer der beiden Männer vom Täter bespuckt und anschließend mit einem
          Faustschlag ins Gesicht niedergeschlagen. Dabei verlor er zunächst das
          Bewusstsein und erlitt einen Kieferbruch, der im Universitätsklinikum
          Regensburg operiert werden musste.
          <br />
          Das junge, schwule Paar stammt aus dem Landkreis Landshut und ist
          Mitglied im gemeinnützigen Verein 'Queer in Niederbayern e. V.' "Als
          Vorstand des Vereins sind wir von diesem Vorfeld schwer erschüttert
          und hart getroffen, zumal wir im Vorfeld diese erste queere Party
          unterstützt haben", sagt Herbert Lohmeyer, der Co-Vorsitzende. Er fügt
          hinzu: "Der Vorfall zeigt uns, dass wir als LSBTIQ*-Community noch
          stärker sichtbar werden und die gesellschaftliche Unterstützung und
          Akzeptanz einfordern müssen".
          <br />
          Die Straftat wurde zur Anzeige gebracht und die Aufnahmen der
          Videoüberwachung im Eingangsbereich des Clubs wurden sichergestellt.
          Der Veranstalter ist ebenfalls erschüttert und kooperiert mit den
          Ermittlungsbehörden. "Wir hoffen sehr, dass der Täter ermittelt wird
          und unser Rechtsstaat mit der vollen Härte unserer Gesetze den
          hasserfüllten Täter verurteilt", sagt Herbert Lohmeyer.
          <br />
          Der Verein Queer in Niederbayern e. V. unterstützt die Forderung nach
          einem bayerischen Aktionsplan gegen Queerfeindlichkeit, Homo- und
          Transphobie sowie einen Bildungsplan. Der Vorsitzende Herbert Lohmeyer
          stellt dazu fest: "Bayern ist das einzige Bundesland, das bisher
          keinen Aktionsplan gegen Homo- und Transphobie erlassen hat und die
          Staatsregierung weigert sich bis heute hartnäckig. Insofern sehen wir
          hier die Staatsregierung in der Pflicht, endlich etwas gegen diese
          stark zunehmenden queerfeindlichen Übergriffe zu tun. Und auch ein
          fehlender Bildungsplan, mit dem in unseren Schulen endlich auch andere
          Lebensweisen als die heteronormative gelehrt werden, ist mit Schuld an
          solchen tätlichen Übergriffen". Und Lohmeyer fordert zudem, dass auch
          in Integrationskursen über unsere liberale Weltanschauung und
          Lebensweisen unterrichtet werden muss.
        </p>
      </NewsItem>
      <NewsItem title="Queer in Niederbayern e.V. unterstützt den offenen Brief - Offener Brief DFB - One Love Kampagne">
        <p className="mid">
          <strong>Offener Brief DFB - One Love Kampagne</strong>
          <br />
          Lieber DFB, Liebe Mannschaft, Liebe Verbündete,
          <br />
          manchmal schaut man in die Welt und bemerkt, dass Kampagnen, die
          eigentlich eine gute Absicht haben, in Wirklichkeit reine Farce sind.
          So auch euer Vorstoß unter dem Hashtag #OneLove eine
          Gemeinschaftskampagne für #Vielfalt und gegen #Diskriminierung zu
          launchen.
          <br />
          Gut gemeint ist eben nicht immer gut gemacht!
          <br />
          Ihr steht mit mehreren Nationalverbänden zusammen dafür ein, was
          eigentlich schon lange vor der Vergabe der WM nach Katar in euren
          Grundsätzen verankert war: Vielfalt, Toleranz, Miteinander und
          Menschenrechte.
          <br />
          Und dennoch habt ihr alle in den letzten Jahren zugesehen, wie
          Menschen sterben, Menschenrechte missachtet wurden und trotzdem
          Millionenverträge geschlossen, die gegen jede Vernunft sprechen.
          <br />
          Ihr seid nicht laut gewesen. Ihr seid nicht zusammen gekommen, um aus
          eigenen Impulsen tätig zu werden. Ihr habt immer noch keine Haltung
          gezeigt und euch als Verbände gegen die Geschehnisse vor Ort, Katar
          als Gastgeberland und die verantwortliche FIFA positioniert.
          <br />
          Ihr schweigt und werdet nur tätig, wenn das Image vom Fußball leidet.
          Wenn Werbeverträge platzen, Lizenzrechte nicht genutzt werden, Spiele
          nicht übertragen werden sollen und somit der eigene Marktwert sinkt.
          Schämt euch!
          <br />
          <br />
          #DONTKICKLGBTIQRIGHTS
          <br />
          <br />
          Bereits zur EM 2020 haben wir, der CSD Deutschland e.V., auf die
          Missstände im Sport, die Missachtung von Menschenrechten in Ländern
          von Turnierteilnehmenden und die Scheinheiligkeit der UEFA
          hingewiesen.
          <br />
          Unter der Kampagne{" "}
          <a
            className="d-pink"
            href="https://csd-deutschland.de/em2020-pressemitteilung-ger-hun/"
            target="_blank"
            rel="noreferrer"
          >
            #DONTKICKLGBTIQRIGHTS
          </a>{" "}
          haben wir zusammen mit Verbündeten Flagge gezeigt, Farbe bekannt und
          den Regenbogen in und an die Stadien gebracht. Eure Verbandsmitglieder
          waren es, die die Botschaft weitergetragen haben, ohne von euch
          Unterstützung zu erfahren.
          <br />
          Abermals schafft ihr, als wohl größter und bekanntester Verband mit
          mehr als sieben Millionen Mitgliedern, zu ignorieren, was die Mehrheit
          beschäftigt. Abermals schafft ihr es nicht, euch gegen Geldgeber und
          Macht zu positionieren. Abermals schafft ihr es nicht, mit denen zu
          sprechen, die sich auskennen: Lesben, Schwule, Bisexuelle, trans*
          Personen, inter* Menschen und alle anderen Queers und Minderheiten,
          die von dieser Fußball-Weltmeisterschaft mit Füßen getreten werden.
          <br />
          “Wenn ich in ein Land reise, in dem nicht ganz klar ist, ob ich
          eingesperrt werde oder sogar zu Tode verurteilt werden kann, dann ist
          das einfach falsch. Da darf kein Fußballturnier stattfinden.”
          <br />
          Ex-Nationalspieler Thomas Hitzelsperger im Podcast der DFB Stiftungen
          “Mehr als ein Spiel” zu Katar als WM-Gastgeberland.
          <br />
          Eure spezielle Kapitänsbinde soll Vielfalt demonstrieren, zeigt aber
          eigentlich nur, welche Angst ihr als Verband vor FiFA und Sponsoren
          habt. Denn wer wirklich glaubwürdig sein möchte und sich gegen
          Diskriminierung stark macht, wer für Menschenrechte einstehen möchte
          und Vielfalt tatsächlich transportieren möchte, der sollte auch das
          nötige Rückgrat besitzen. Gerade in einem Land wie Katar, in dem
          Menschen aufgrund verachtender Gesetze sterben, in dem freie
          Entfaltung der Person eingeschränkt und Individualität verboten sind,
          bedarf es Haltung.
          <br />
          Auch wenn ihr euch gegen euren Geldgeber, die FIFA positioniert, und
          vor Ort gegen geltendes Recht verstoßen würdet, so kann euch der
          Schutz als “Big-Player” auch in Katar garantiert werden. Oder denkt
          ihr allen Ernstes, dass ein ganzes Team, eine ganze
          Nationalmannschaft, verhaftet werden würde, nur weil sie auf dem Platz
          einen stillen - ABER WIRKUNGSVOLLEN - Protest zeigt?
          <br />
          Welche internationalen Reaktionen gäbe es, wenn die Scheichs von Katar
          sich gegen eine Regenbogenbinde positionieren und nochmals deutlich
          machen, wie egal ihnen die Menschenrechte im eigenen Land sind?
          <br />
          Daher fordern wir euch auf:
          <br />
          Gebt eurer Kampagne Glaubwürdigkeit! Anstatt eine eigene Farbskala zu
          kreieren steht der Regenbogen weltweit für Vielfalt,
          Gleichberechtigung und Frieden! Nutzt den Regenbogen für eure “One
          Love” - Kampagne.
          <br />
          Außerdem fordern wir, dass bei allen zukünftigen Abstimmungen die
          Menschenrechtslage ein wesentlicher Bestandteil der Auswahlkriterien
          von Austragungsländern sein muss. Auch um den Preis, die Teilnahme in
          solchen Ländern zu verweigern. Der deutsche und der europäische
          Fussball haben diese Kraft. Denn der europäische Markt ist weiterhin
          der wichtigste Ort für den internationalen Fussball.
          <br />
          Nutzt diese Verantwortung und lasst eurer Charta für Ethik Taten
          folgen.
          <br />
          CSD Deutschland e.V.
          <br />
          Der Vorstand
        </p>
      </NewsItem>
      <NewsItem title="CSDs in Niederbayern 2022 und mehr">
        <p className="mid">
          Nach unserem 3. CSD Kelheim am 04.06.2022 und dem 3. CSD Passau am
          09.07.2022 stehen unter dem diesjährigen Motto{" "}
          <strong className="d-blue">SICHTBARKEIT SCHAFFT SICHERHEIT</strong>{" "}
          noch Straubing und Landshut bevor.
          <br />
          <br />
          Unser <strong className="d-blue">3. CSD in Straubing</strong> findet
          am{" "}
          <strong className="d-blue">
            Samstag, den 10. Sept. 2022 ab 15:00 Uhr
          </strong>{" "}
          auf dem Ludwigsplatz statt. Der Demonstrationszug wird ab 14:00 Uhr
          auf dem Festplatz "Am Hagen" aufgestellt. Die Schirmherrschaft hat der
          3. Bürgermeister Werner Schäfer (SPD) übernommen.
          <br />
          <br />
          Der <strong className="d-blue">4. CSD Landshut</strong> findet, wie
          jedes Jahr, am letzten Samstag im September, diesmal also am{" "}
          <strong className="d-blue">Samstag, den 24.09.2022</strong> auf der{" "}
          <strong className="d-blue">Mühleninsel</strong> statt.
          <br />
          Die Schirmherrschaft hat wiederum der Landshuter Oberbürgermeister
          Alexander Putz (parteilos) übernommen.
          <br />
          In diesem Jahr findet endlich wieder ein Demonstrationszug durch die
          Innenstadt statt. Ziel ist die Mühleninsel. Dort bieten wir euch
          wieder ein regenbogenbuntes Bühnenprogramm mit Kundgebung, Musik,
          kleinen Podiumsdiskussion, Showacts und Livebands. Auch für das
          leibliche Wohl ist mit einem großen gastronomischen Angebot bestens
          gesorgt. Zudem kannst du dich an vielen Infoständen umfangreich
          informieren.
          <br />
          Ehrengast und Vertreterin der Bundesregierung ist die Staatsministerin
          für Kultur und Medien, Claudia Roth MdB.
          <br />
          Wenn du uns bei der umfangreichen Organisation, als Helfer*in und/oder
          als Ordner*in unterstützen und Teil des tollen Teams werden möchtest,
          dann melde dich bei uns unter{" "}
          <a className="d-blue" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
          .
        </p>
      </NewsItem>
      <NewsItem title='Infostand zum "Coming-Out-Day"'>
        <p className="mid">
          Zum diesjährigen "Coming-Out-Days" (11. Oktober) wird der Verein
          "Queer in Niederbayern e. V." am Samstag, 15. Oktober in der Zeit von
          ca. 10:30 Uhr bis 16:00 Uhr in der Landshuter Altstadt vor dem Rathaus
          einen Informations- und Aktionsstand haben. Für besondere
          Aufmerksamkeit werden besondere Aktionen sorgen (mehr dazu später).
          <br />
          Zudem stehen Mitglieder des Vereins allen Interessierten mit
          Informationen, eigenen Erfahrungen und ggf. auch mit Tipps zur
          Verfügung. Dabei werden auch entsprechende Informationsbroschüren
          kostenlos vorgehalten, z. B. für Jugendliche in der Selbstfindungs-
          oder Coming-Out-Phase oder auch für Eltern von queeren Jugendlichen.
          Es werden auch verschiedene Broschüren in den Sprachen Farsi,
          Türkisch, Russich und Englich bereitgehalten. Außerdem besteht die
          Möglichkeit sich auch über externe Ansprechpartner und entsprechenden
          Beratungseinrichtungen zu informieren.
          <br />
          Die anwesenden Mitglieder von Queer in Niederbayern stehen auch für
          alle Fragen rund um das Coming-Out und queere Lebensweisen zur
          Verfügung. Gleichzeitig finden auch betroffene Geflüchtete
          Ansprechpartner und Unterstützung.
          <br />
          Für interessierte Menschen, die sich vielleicht nicht trauen, den
          Verein direkt am Infostand anzusprechen, können das gerne auch über
          das Kontaktformular auf dieser Homepage tun.
          <br />
          Wir freuen uns auf zahlreichen Besuch an unserem Stand.
        </p>
      </NewsItem>
      <NewsItem title="Jugendliche hinterfragen immer öfter geschlechtliche Identität">
        <p className="mid">
          Immer mehr Kinder und Jugendliche hinterfragen ihre geschlechtliche
          Identität, viele fragen sich auch, ob sie im richtigen Körper stecken.
          Der Beratungsbedarf dazu ist stark gestiegen. Das berichten Ärzte und
          die LGBTQ*-Community.{" "}
          <a
            className="d-blue"
            href="https://www.br.de/nachrichten/bayern/lgbtq-jugendliche-hinterfragen-oefter-geschlechtliche-identitaet,Sza7dDp"
            target="_blank"
            rel="noreferrer"
          >
            Mehr Infos im Original-Artikel von BR 24
          </a>
          .
        </p>
      </NewsItem>
      <NewsItem title="Leserbrief - 125 extrem Mutige - es wurde Zeit">
        <p className="mid">
          Die Aktion der 125 Beschäftigen der katholischen Kirche, sich als
          "Queer" zu outen, also als schwul, lesbisch, bisexuell, trans*, inter*
          und queer zu bekennen, ist vor allem eins: extrem mutig. Und es ist
          tatsächlich ein Bekenntnis, denn wer sich andauernd verstecken muss,
          läuft Gefahr, an der queerfeindlichen Atmosphäre in den katholischen
          Einrichtungen zu ersticken.
          <br />
          Ihr öffentliches Coming-out ist kein identitätspolitisches
          Schmusedeckchen, sondern ein dringend nötiger Akt, um der eisigen
          Atmosphäre der Verfolgung queerer Lebensart zu entkommen. Was sie tun,
          sich nämlich mit ihrem Gesicht zu zeigen, kann im System der
          katholischen Kirche in Deutschland eigentlich nur schlimme Folgen
          haben. Im Zweifel verlieren sie ihre Arbeit und damit ihre
          Existenzgrundlage. Schwul, lesbisch, bi- oder intersexuell, trans*
          oder queer zu sein, wird arbeitsrechtlich hart sanktioniert. Es kann
          bis hin zur Entlassung führen. Dass es nun so viele sind, dich sich
          outen, schützt leider nur begrenzt.
          <br />
          Die vatikanischen Vertreter, - alles wirklich bekennend heterosexuelle
          Männer, - vom Bischof bis zum Personalreferenten in der katholischen
          Sozialeinrichtung, haben freilich momentan keine gute Presse. Das
          Vertuschen sexuellen Missbrauchs und das Schützen der Täter sind so
          bezeichnend, dass für die Führungsleute der katholischen Kirche in
          Deutschland nur dies zu bilanzieren ist: ein moralischer Bankrott.
          <br />
          Der Klerus war einst selbst in Deutschland, der säkularen Heimat des
          emeritierten Papstes Benedikt XVI., so mächtig, dass er kalt lächelnd
          und durchsetzungsbewusst bis in die letzte politische Verästelung
          agieren konnte, ohne sich je rechtfertigen zu müssen. Doch inzwischen
          ist er kaum mehr noch als ein sektoider Schreckenshaufen, bar dessen,
          wofür sie sich zuständig fühlen: die Liebe Gottes.
          <br />
          Jetzt ist die "Ampel" gefragt. Gerade die katholische Kirche hat dafür
          gesorgt, dass menschliche Leben schikaniert und entwürdigt wurden.
          Ihre Würdenträger waren oft kaum mehr als eine Horde enthemmter
          Heuchler. Das queere Selbstbewusstsein der 125 werden sie nicht mit
          einer Fülle von Kündigungen beantworten können. Wenn sich diese
          Couragierten nun wünschen, überhaupt müsse es ein Ende haben mit
          stereotypischen, patriarchalen Geschlechterrollen und der exklusiven
          Wertschätzung des Sexuellen, das ausschließlich der Fortpflanzung
          dient, ist das berechtigt. Aber dieser Weg wird lang und steinig, der
          Vatikan denkt gern in Jahrhunderten, nicht tagesaktuell.
          <br />
          Wichtiger ist es nun, eine Veränderung des Arbeitsrechts zu erreichen.
          Bislang fallen die Kirchen als Tendenzbetriebe nicht unter das
          Betriebsverfassungs- und Antidiskriminierungsgesetz. Sie konnten und
          können immer sagen, dass ein queerer Lebensentwurf nicht mit ihrem
          Glauben vereinbar sei. Damit muss es nun dringend ein Ende haben, sei
          es in Konfessionsschulen, karitativen Einrichtungen oder kirchlichen
          Verwaltungen, Mit der CDS/CSU wäre dies nicht möglich, aber die Union
          ist nicht mehr Bundesregierung. Also Ampel, worauf warten?
          <br />
          <br />
          Herbert Lohmeyer u. Chris Hess
        </p>
      </NewsItem>
      <NewsItem title="Treffen der Trans*gruppe koalaTee">
        <p className="mid">
          Am 31. Januar trifft sich wieder unsere Trans* Gruppe koalaTee.
          Aufgrund der aktuellen Corona-Auflagen ist dies nur online möglich.
          Wenn du an unserem Treffen auf Zoom teilzunehmen möchtest, melde dich
          einfach kurz unter{" "}
          <a
            className="d-blue"
            href="mailto:koalatee@queer-niederbayern.de"
            target="_blank"
            rel="noreferrer"
          >
            koalatee@queer-niederbayern.de
          </a>
          . Wir freuen uns auf Teilnehmer:innen aller Altersklassen. Auch
          Angehörige von Trans*menschen sind willkommen.Hier weiter lesen.
        </p>
      </NewsItem>
      <NewsItem title="Queer Refugees Expert:innengespräch">
        <p className="mid">
          (english below)
          <br />
          Queere Geflüchtete gelten als besonders vulnerabel, da sie neben
          Rassismus und Diskriminierung durch ihren rechtlichen Status auch
          starker Queerfeindlichkeit ausgesetzt sind. Dies zeigt sich nicht nur
          im privaten Alltag der Menschen, sondern wird auch beim Asylverfahren
          deutlich. Wo genau die Herausforderungen für queere Geflüchtete liegen
          und welche politischen Lösungen es für diese geben kann, soll in
          diesem Expert*innengespräch diskutiert werden. Die
          Veranstaltungssprache ist Englisch (Lasst euch davon bitte nicht
          abschrecken, wir werden so leicht verständlich wie möglich sprechen).
          <br />
          Seid dabei und diskutiert mit uns über dieses wichtige und aktuelle
          Thema: Meldet euch direkt bei{" "}
          <a
            className="d-blue"
            href="mailto:johannes.hunger@gruene-landshut.de"
            target="_blank"
            rel="noreferrer"
          >
            johannes.hunger@gruene-landshut.de
          </a>{" "}
          oder joined uns{" "}
          <a
            className="d-blue"
            href="https://fb.me/e/L0rUmtWd?ti=wa"
            target="_blank"
            rel="noreferrer"
          >
            auf Facebook
          </a>
          .<br />
          <br />
          Queer refugees are considered to be particularly vulnerable because,
          in addition to racism and discrimination due to their legal status,
          they are also exposed to strong queer hostility. This is not only
          evident in people's everyday private lives, but also influences asylum
          procedures. Where exactly the challenges for queer refugees lie and
          what political solutions there are, we will discuss. This event is a
          cooperation project of the Green Youth Landshut, Haus International
          and Queer in Niederbayern e.V. We warmly invite all interested people
          to register by sending a mail to{" "}
          <a
            className="d-blue"
            href="mailto:johannes.hunger@gruene-landshut.de"
            target="_blank"
            rel="noreferrer"
          >
            johannes.hunger@gruene-landshut.de
          </a>{" "}
          or{" "}
          <a
            className="d-blue"
            href="https://fb.me/e/L0rUmtWd?ti=wa"
            target="_blank"
            rel="noreferrer"
          >
            join the Facebook event
          </a>{" "}
          and send us a private message. Then you will receive a link to the
          event. The language of the event is English.
        </p>
      </NewsItem>
      <NewsItem title="Predigten die spalten statt vereinen">
        <p className="mid">
          Die{" "}
          <a
            className="d-blue"
            href="https://stefan-oster.de/ist-die-klassische-familie-heute-noch-das-normale/"
            target="_blank"
            rel="noreferrer"
          >
            Predigt des Passauer Bischofs Stefan Oster
          </a>
          , welche dieser am 27.12.2020 zum Fest der Heiligen Familie im
          Passauer Dom unter dem Motto „Ist die klassische Familie heute noch
          das Normale?“ hielt, hat in den vergangenen Tagen für Empörung und
          Unverständnis gesorgt. Auch unser Vereinsvorstand wendet sich mit
          einem{" "}
          <a
            className="d-blue"
            href="assets/files/QIN_Offener Brief an Bischof Oster.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Offenen Brief an ihn
          </a>{" "}
          und das Passauer Bistum.
          <br />
          Nicht nur wir, auch der{" "}
          <a
            className="d-blue"
            href="https://bayern.lsvd.de/religion/predigten-die-nicht-vereinen/?fbclid=IwAR2Lwlj5neaoNsbnkI2vlVPB5K7XKYM1QIwk607sifA-Slc_CV3YuSavivI"
            target="_blank"
            rel="noreferrer"
          >
            LSVD Bayern e. V. hat reagiert
          </a>
          . Weiterhin folgte diese{" "}
          <a
            className="d-blue"
            href="https://stefan-oster.de/ein-offener-brief-der-verkuerzt-und-meine-offene-antwort-darauf/"
            target="_blank"
            rel="noreferrer"
          >
            Antwort von Bischof Stefan Oster
          </a>{" "}
          und ein{" "}
          <a
            className="d-blue"
            href="assets/files/DiplPsych_AH_BSO.pdf"
            target="_blank"
            rel="noreferrer"
          >
            weiterer offener Brief
          </a>{" "}
          des Dipl.-Psychologen und Paar- u. Familientherapeuten Anton
          Hergenhan.
          <br />
          Als Queer in Niederbayern e. V. unterstützen wir auch die Anfrage
          unseres Partnervereins{" "}
          <a
            className="d-blue"
            href="https://www.equality-oberpfalz.de/"
            target="_blank"
            rel="noreferrer"
          >
            equality Oberpfalz e.V.
          </a>{" "}
          an die Antidiskriminierungsstelle des Bundes zur Überprüfung, ob ich
          Verstöße gegen das Antidiskriminierungsgestzes verstoßen und eine
          Anzeige sinnvoll sein kann.
        </p>
      </NewsItem>
      <NewsItem title="Treffen der Trans* Gruppe koalaTee">
        <p className="mid">
          Am 03.01.2021 trifft sich zum ersten Mal unsere Trans* Gruppe
          koalaTee. Aufgrund der aktuellen Corona-Auflagen ist dies nur online
          möglich. Wenn du an unserem Treffen auf Zoom teilzunehmen möchtest,
          melde dich einfach kurz unter{" "}
          <a
            className="d-blue"
            href="mailto:koalatee@queer-niederbayern.de"
            target="_blank"
            rel="noreferrer"
          >
            koalatee@queer-niederbayern.de
          </a>
          . Wir freuen uns auf Teilnehmer:innen aller Altersklassen. Auch
          Angehörige von Trans*menschen sind willkommen.
        </p>
      </NewsItem>
      <NewsItem title="CSDs 2021 in Niederbayern & mehr">
        <p className="mid">
          Am 19.06.2021 veranstalten wir den 2. CSD Straubing.
          <br />
          Den 2. CSD in Kelheim planen wir für das 2. Juli-Wochenende 2021.
          <br />
          Der 3. CSD in Landshut findet wieder am letzten Samstag im September,
          also am 25.09.2021 statt.
          <br />
          Und auch in Passau planen wir einen CSD. Der Termin ist noch in
          Abstimmung.
          <br />
          Wenn du uns bei den umfangreichen Organisationen helfen und Teil des
          Teams werden möchtest, dann melde dich bei uns unter{" "}
          <a className="d-blue" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
        </p>
        <p className="mid">
          Der Gesamtverein "Queer in Niederbayern e.V." ist seit 2019 Mitglied
          im Dachverband "CSD Deutschland e.V." und seit 2020 auch Mitglied im
          Lesben- und Schwulenverband Deutschland{" "}
          <a
            className="d-blue"
            href="https://www.lsvd.de/"
            target="_blank"
            rel="noreferrer"
          >
            LSVD
          </a>{" "}
          - Landesverband Bayern e.V."
          <br />
          Nach den Jugendgruppen Landshut, Straubing und Deggendorf, sind nun
          auch unsere Jugendgruppen Passau, Rottal-Inn und Dingolfing Mitglied
          im Jugendnetzwerk "Lambda Bayern e.V.".
        </p>
      </NewsItem>
      <NewsItem title='Infostände zum "Coming-Out-Day"'>
        <p className="mid">
          Anlässlich des "Internationalen Coming-Out-Days" wird der Verein
          "Queer in Niederbayern e. V." am Samstag, 10. Oktober in der Zeit von
          10:00 Uhr bis 16:00 Uhr in der Landshuter Altstadt vor dem Rathaus und
          in Straubing am Ludwigsplatz jeweils einen Infostand haben. Mitglieder
          des Vereins stehen allen interessierten Bürgerinnen und Bürgern mit
          Informationen, eigenen Erfahrungen und ggf. auch mit Tipps zur
          Verfügung. Dabei werden auch entsprechende Informationsbroschüren
          kostenlos vorgehalten, z. B. für Jugendliche in der Selbstfindungs-
          oder Coming-Out-Phase oder auch für Eltern von queeren Jugendlichen.
          Es werden auch verschiedene Broschüren in den Sprachen Farsi,
          Türkisch, Russich und Englich bereitgehalten. Außerdem besteht die
          Möglichkeit sich auch über externe Ansprechpartner und entsprechenden
          Beratungseinrichtungen zu informieren.
          <br />
          Die anwesenden Mitglieder von Queer in Niederbayern stehen auch für
          alle Fragen rund um das Coming-Out und queere Lebensweisen zur
          Verfügung. Gleichzeitig finden auch betroffene Geflüchtete
          Ansprechpartner und Unterstützung.
          <br />
          Für interessierte Menschen, die sich vielleicht nicht trauen, den
          Verein direkt am Infostand anzusprechen, können das gerne auch über
          das Kontaktformular auf dieser Homepage tun.
          <br />
          Wir freuen uns auf zahlreichen Besuch an unserem Stand, wobei auf die
          Abstandsregeln und das Tragen eines Mund-Nasen-Schutzes hinweisen.
        </p>
      </NewsItem>
      <NewsItem title="2. LGBTIQ*-Stammtisch Niederbayern">
        <p className="mid">
          Wir laden alle LGBTIQ*-Menschen und alle Unterstützer*innen zu unserem
          2. offenen queeren Stammtisch am Donnerstag, 8. Oktober um 19:30 Uhr
          ins Gasthaus "Zum Krenkl", Altstadt 107 in Landshut herzlich ein.
          <br />
          Unser Angebot richtet sich an LGBTQs und Freunde, die Lust haben, sich
          in entspannter, toleranter Atmosphäre zu treffen. Dabei steht der Spaß
          und die gute Unterhaltung im Vordergrund. Wir wollen aber auch Raum
          zum Erfahrungsaustausch und Fragen bieten, z.B. hinsichtlich Coming
          out, "schwule Väter", Diskriminierung am Arbeitsplatz, etc. in einem
          vertraulichen Umfeld anbieten.
          <br />
          Bei uns kann man in einem parteipolitisch neutralen Umfeld andere
          lesbische, schwule, bisexuelle, inter- und transsexuelle, queere und
          auch heterosexuelle Leute kennenlernen. Denn Jede und Jeder soll die
          Möglichkeit haben, nach ihrer/seiner Art leben und lieben zu können.
          <br />
          Der Stammtisch ist für alle Interessierten offen. Eine Anmeldung ist
          nicht erforderlich. Interessent*innen können sich aber auch gerne
          unter unserer{" "}
          <a className="d-blue" href="mailto:stammtisch@queer-niederbayern.de">
            Mailadresse
          </a>{" "}
          melden.
        </p>
      </NewsItem>
      <NewsItem title='"Queer Beats" Open Air im RocketClub'>
        <p className="mid">
          Am Vorabend der niederbayerischen CSDs 2020 in Landshut, Straubing und
          Kelheim, lädt der Verein Queer in Niederbayern am 25. September unter
          dem Motto{" "}
          <a
            className="d-blue"
            href="https://www.facebook.com/events/326303031849500/?acontext=%7B%22event_action_history%22%3A[%7B%22mechanism%22%3A%22search_results%22%2C%22surface%22%3A%22search%22%7D]%7D"
            target="_blank"
            rel="noreferrer"
          >
            „Queer Beats“
          </a>{" "}
          zu einem queeren Open Air im{" "}
          <a
            className="d-blue"
            href="https://www.rocketclub.de/events/category/termine/"
            target="_blank"
            rel="noreferrer"
          >
            Landshuter Rocket Club
          </a>{" "}
          ein. Freut euch auf ein queerfeministisches musikalisches Programm mit
          den großartigen DJs* Bi-Män vom Münchner WUT-Kollektiv und DJ Nesstor
          von Das Netzwerk / NachtEin.TagAus.Natürlich findet alles mit
          Corona-Sicherheitsmaßnahmen statt. Wir passen aufeinander auf: Haltet
          Abstand und tragt eure Masken! <br />
          <strong className="d-blue">Line Up:</strong>
          <br />
          Einlass ab 17.00 Uhr <br />
          18.00 – 20.00 Bi-Män / WUT-Kollektiv
          <br />
          20.00 – 22.00 Nesstor / Das Netzwerk / NachtEin.TagAus.
          <br />
          Eintritt auf Spendenbasis. Die Spenden kommen Queer in Niederbayern
          e.V. zu Gute.
        </p>
      </NewsItem>
      <NewsItem title="Erster queerer Stammtisch">
        <p className="mid">
          Es ist soweit... wir treffen uns zu unserem ersten{" "}
          <strong className="d-blue">queeren Stammtisch</strong>. Dazu laden wir
          euch sehr herzlich für den kommenden{" "}
          <strong>Dienstag, 25. August 2020, ab 19:30 Uhr</strong> ins{" "}
          <strong>Gasthaus "Weißes Bräuhaus Zum Krenkl"</strong> Altstadt 107
          (Ecke Ländgasse) in 8402 Landshut ein. Der Tisch ist im Nebenzimmer
          auf den Verein "Queer in Niederbeyern e. V." reserviert. Wir sitzen
          also nicht mitten im Gasthaus, keine Sorge. Einfach danach fragen...
          <br />
          Wir wollen uns in lockerer, entspannter Atmosphäre zum Kennenlernen,
          Ratschen, Philosophieren, Welt verbessern, und vieles mehr, treffen...
          also was man eben an einem Stammtisch zu macht. Also ganz ungezwungen
          für die LGBTIQ*-Community ein Stammtisch-Angebot.
          <br />
          Gerne bieten wir diesen "queeren Stammtisch" monatlich bzw. regelmäßig
          an - ganz wie es sich entwickelt. An welchem Wochentag, das werden wir
          gemeinsam sehen.
          <br />
          Wir freuen uns auf eure Teilnahme.
        </p>
      </NewsItem>
      <NewsItem title="2. CSD in Niederbayern wird stattfinden">
        <p className="mid">
          Statt auf einer einzigen Demo begrüßt euch das Team von Queer in
          Niederbayern e.V. dieses Jahr gleich auf 3 parallel-Terminen. Die 3
          CSD-Demos werden gleichzeitig in 3 verschiedenen Städten in
          Niederbayern stattfinden: Landshut, Kelheim und Straubing sind am
          26.09.2020 ab 14:00 Uhr dabei die Regenbogenflaggen in den Straßen der
          Städte zu schwingen. Im Anschluss lauschen wir mit euch prominenten
          Redner*innen aus jeder Stadt.
          <br />
          Wir brauchen dringend eure Unterstützung! Durch die aktuelle Lage
          benötigen wir in allen drei Städten mehr Ordner*innen als erwartet.
          Meldet euch direkt{" "}
          <a className="d-blue" href="mailto:kontakt@queer-niederbayern.de">
            per Mail
          </a>{" "}
          oder über unser{" "}
          <a className="d-blue" href="/contact">
            Kontaktformular
          </a>{" "}
          um uns zu unterstützen!
        </p>
        <p className="mid center">Die Vorfreude beginnt!</p>
      </NewsItem>
      <NewsItem title="Queeres Leben in Bayern">
        <p className="mid">
          Im Mai diesen Jahres kam eine{" "}
          <a
            className="d-blue"
            href="https://www.gruene-fraktion-bayern.de/fileadmin/bayern/user_upload/download_dateien_2018/Pressekonferenzen/14-5-2020-Ergebnisse_QueeresLebenBayern_final.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Studie
          </a>{" "}
          in Zusammenarbeit von Bündnis 90, die Grünen, und der Hochschule
          Landshut heraus zum Thema "Queeres Leben in Bayern 2020". Die Studie
          zeigt uns, dass unsere Arbeit noch lang nicht vorbei ist. Das Fazit
          der Studie:
          <br />
          - "Diskriminierungen gegen queere Menschen in Bayern [ist] stark
          ausgeprägt"
          <br />
          - "Studie zeigt Existenz besonders vulnerabler Gruppen innerhalb
          queerer Menschen"
          <br />
          - Und wirft auch die Frage auf: "Inwieweit werden deren spezifische
          Lebensbedingungen und Bedürfnisse berücksichtigt?"
          <br />
          Eine Kurzfassung der Studie findet ihr{" "}
          <a
            className="d-blue"
            href="https://www.haw-landshut.de/fileadmin/Hochschule_Landshut_NEU/Ungeschuetzt/Aktuelles/Presse/Bilder/News/2020/2020-05/PK_Studienvorstellung-QueeresBayern_AWagner.pdf"
            target="_blank"
            rel="noreferrer"
          >
            hier
          </a>
          , die vollständige Studie steht euch außerdem{" "}
          <a
            className="d-blue"
            href="https://www.gruene-fraktion-bayern.de/fileadmin/bayern/user_upload/download_dateien_2018/Pressekonferenzen/14-5-2020-Ergebnisse_QueeresLebenBayern_final.pdf"
            target="_blank"
            rel="noreferrer"
          >
            hier zum Download
          </a>{" "}
          zur Verfügung.
        </p>
        <p className="mid">
          Quelle: © Landtagsfraktion Bündnis90/Die Grünen sowie Alis Wagner,
          Hochschule Landshut mit Prof. Dr. Barbara Thiessen u. Prof. Dr.
          Bettina Leibetseder, Mai 2020
        </p>
      </NewsItem>
      <NewsItem title="Absage des CSD Aufgrund der aktuellen Corona-Pandemie">
        <p className="mid">
          Schweren Herzens hat sich der Verein Queer in Niederbayern e.V. dafür
          entscheiden müssen den geplanten diesjährigen Christopher-Street-Day
          abzusagen. Zumindest in dem Format in dem er ursprünglich geplant war.{" "}
          <br />
          Mit Hochdruck arbeiten wir nun daran allen queeren Menschen in Bayern
          trotz der unsicheren Lage eine Alternative zu bieten, um zu zeigen,
          dass wir uns weiterhin für die Rechte von euch einsetzen.
        </p>
      </NewsItem>
      <NewsItem title="2. CSD Niederbayern am Samstag, 26. Sept. 2020 in Landshut">
        <p className="mid">
          Trotz der derzeitig schwierigen Situation in der Corona-Pandemie sind
          wir zuversichtlich, dass unser 2. CSD Niederbayern am Samstag, 26.
          Sept. 2020 durchgeführt werden kann. Wir sind jedenfalls mitten in der
          Organisation und der Planung. Also, seit auch zuversichtlich, dass wir
          den CSD Ende September veranstalten können. Drückt die Daumen.
          <br />
          Und wer uns in der Organisation unterstützen möchte, kann sich gerne
          unter{" "}
          <a className="d-blue" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>{" "}
          bei uns melden.
        </p>
      </NewsItem>
      <NewsItem title='Wir sind Mitglied bei "Lambda Bayern e. V."'>
        <p className="mid">
          Mit unseren beiden Jugendgruppen in Landshut und in Straubing sind wir
          nun ganz frisch Mitglied bei Lambda Bayern e. V.
          <br />
          Lambda Bayern ist der bayerische Dachverband der LesBiSchwulen und
          Trans Jugendgruppen im Freistaat. Hier werden unsere Jugendgruppen
          unterstützt und unsere Jugendgruppenleiter erhalten bei Lambda Bayern
          die Jugendleiterausbildung. Näheres findst Du unter{" "}
          <a
            className="d-blue"
            href="https://www.lambda-bayern.de"
            target="_blank"
            rel="noreferrer"
          >
            https://www.lambda-bayern.de
          </a>
          .
        </p>
      </NewsItem>
      <NewsItem title="LGBTIQ*-Jugendgruppen" noHR={true}>
        <p className="mid">
          Ganz neu: wir haben jetzt auch eine queere Jugendgruppe in{" "}
          <strong>Straubing</strong>. Die Treffen finden zunächst einmal im
          Monat donnerstags statt. Wegen der Corona-Pandemie finden jedoch z.
          Zt. keine Treffen statt.
          <br />
          Wenn du zwischen 14 und 27 Jahren bist und Interesse an der
          Jugendgruppe SR für lesbische, schwule, bi-, trans-, intersexuellen
          und queeren Jugendlichen hast, dann schau hier auf der Website unter{" "}
          <a className="d-blue" href="youthgroups.php">
            JUGENDGRUPPE
          </a>{" "}
          und melde dich doch unter{" "}
          <a
            className="d-blue"
            href="mailto:sr-jugendgruppe@queer-niederbayern.de"
          >
            sr-jugendgruppe@queer-niederbayern.de
          </a>
          . Wir freuen uns auf dich.
        </p>
        <p className="mid">
          Die LGBTIQ*-Jugendgruppe <strong>Landshut</strong> trifft sich seit
          Mitte Dezember 2019 immer am 1. Montag und am 3. Donnerstag im Monat
          im "Infoladen" in Landshut. Wegen der Corona-Pandemie finden jedoch z.
          Zt. keine Treffen statt.
          <br />
          Wenn du zwischen 14 und 27 Jahren bist und Interesse an der
          Jugendgruppe LA für lesbische, schwule, bi-, trans-, intersexuellen
          und queeren Jugendlichen hast, dann schau hier auf der Website unter{" "}
          <a className="d-blue" href="youthgroups.php">
            JUGENDGRUPPE
          </a>{" "}
          und melde dich doch unter{" "}
          <a
            className="d-blue"
            href="mailto:LA-Jugendgruppe@queer-niederbayern.de"
          >
            LA-Jugendgruppe@queer-niederbayern.de
          </a>
          . Wir freuen uns auf dich.
        </p>
        <p className="mid">
          <strong className="d-blue">
            Jugendgruppen in Passau und Deggendorf
          </strong>
          <br />
          Du vermisst oder suchst schon länger in deiner Region eine
          Jugendgruppe für lesbische, schwule, bi- trans- intersexuelle und
          queere junge Menschen zwischen 14 und 27 Jahren? Dann melde dich bei
          uns! Wir sind derzeit dabei, auch für die{" "}
          <strong>Region Passau</strong> (Stadt und Landkreis Passau, Lkr.
          Freyung-Grafenau, etc.) in Passau und für die{" "}
          <strong>Region Rottal-Inn</strong> (Arnsdorf, Eggenfelden,
          Pfarrkirchen, Simbach am Inn, etc.) in Pfarrkirchen eine queere
          Jugendgruppen aufzubauen. Wenn Du Interesse hast, in einer queeren
          Jugendgruppe mitzumachen, Gleichgesinnte kennenzuleren oder einfach
          mal ganz unverbindlich vorbei zu kommen, dann melde dich bei uns.
          Kontaktiere unseren Jugensvorstand unter{" "}
          <a
            className="d-blue"
            href="mailto:jugendgruppen@queer-niederbayern.de"
          >
            jugendgruppen@queer-niederbayern.de
          </a>
          , über unsere{" "}
          <a className="d-blue" href="/contact">
            Kontaktseite
          </a>{" "}
          oder per WhatsApp an Herbert unter{" "}
          <a className="d-blue" href="tel:01723280621">
            0172 / 3280621
          </a>
          . Die Jugendgruppenleiter*innen nehmen dann mit dir Kontakt auf.
        </p>
      </NewsItem>
    </SubPages>
  );
}
