import { Col, Row } from "react-bootstrap";

import FooterPic from "./FooterPic";
import CSDDeutschland from "../../assets/img/Logo-CSD-Deutschland.png";
import IWWIT from "../../assets/img/comingOut.gif";
import LSVD from "../../assets/img/LogoLSVD.png";
import Lambda from "../../assets/img/lambda-logo.png";
import QueeresNetzwerk from "../../assets/img/queeres-netzwerk-bayern-logo-lang.svg";
import Strong from "../../assets/img/STRONG-Logo-nosubline.svg";

export default function Footer() {
  return (
    <footer className="padtopbottom1rem">
      <Row className="margin-off">
        <FooterPic
          link="https://csd-deutschland.de/"
          src={CSDDeutschland}
          linkText="CSD Deutschland e.V."
        ></FooterPic>
        <FooterPic
          link="https://www.lambda-bayern.de/"
          className="footerpicLambda"
          src={Lambda}
          linkText="Lambda"
        ></FooterPic>
        <FooterPic
          link="https://www.lsvd.de/"
          className="footerpicLSVD"
          src={LSVD}
          linkText="LSVD"
        ></FooterPic>
        <FooterPic
          link="https://queeresnetzwerk.bayern/"
          src={QueeresNetzwerk}
          linkText="Queeres Netzwerk Bayern"
        ></FooterPic>
        <FooterPic
          link="https://strong-community.de/"
          className="footerpicStrong"
          src={Strong}
          linkText="Strong!"
        ></FooterPic>
        <FooterPic
          link="https://www.iwwit.de/"
          className="footerpicLSVD"
          src={IWWIT}
          alt="IWWIT Coming Out Gif"
        ></FooterPic>

        <Col md={{ span: 3, offset: 2 }}>
          <p className="footer-right">
            <a className="l-gray" href="data-protection">
              Datenschutz
            </a>{" "}
            |{" "}
            <a className="l-gray" href="impressum">
              Impressum
            </a>{" "}
            | Copyright © <span>{new Date().getFullYear()}</span> QiN
          </p>
        </Col>
      </Row>
    </footer>
  );
}
