import { Col, Row } from "react-bootstrap";
import PrEP from "../assets/img/prep.png";
import TextAndPic from "../components/UI/TextAndPic";
import Anmeldung from "../assets/files/Satzung.pdf";
import Kelheim from "../assets/img/Kel_2023.png";
import Landshut from "../assets/img/La_2023.png";
import MainPages from "../components/MainPages";
import RowColFull from "../components/UI/RowColFull";
import Straubing from "../assets/img/Str_2023.png";
import Rewe from "../assets/img/REWE_Negativ.png";
import PicLink from "../components/UI/PicLink";

const subtitle = (
  <p>
    Der Christopher Street Day erinnert an den Aufstand Homo- und Transsexueller
    gegen Polizeigewalt und Repressionen im Juni 1969 in der New Yorker
    Christopher-Street im Stadtviertel Greenwich Village. Wir demonstrieren
    damit heute gegen Ausgrenzung und für Toleranz und Akzeptanz. Deshalb ist
    der CSD eines unserer wichtigsten und größten Projekte.
    <br />
    Der Christopher-Street-Day (CSD) ist ein Gedenk-, Demonstrations- und
    Festtag von Lesben, Schwulen. Bisexuellen, Tans*-Menschen, Intersexuellen
    und allen queeren Menschen. An diesem Tag wird für die Rechte dieser Gruppen
    sowie gegen Diskriminierung und Ausgrenzung demonstriert. Die größten
    Demonstrationen anlässlich des CSD im deutschsprachigen Raum finden in
    Berlin und Köln statt. Die von uns, Queer in Niederbayern e. V.,
    organisierten und veranstalteten CSDs finden in Kelheim, Straubing und in
    Landshut statt.
  </p>
);

export default function CSDAndNews() {
  return (
    <MainPages
      topImage="csd-img"
      bottomImage="csd-img2"
      title="Christopher Street Day & weitere News"
      subtitle={subtitle}
    >
      <hr className="padtopbottom1rem" />
      <TextAndPic
        link="https://www.iwwit.de/prep-engpass"
        linkText="PrEP Lieferengpass"
        src={PrEP}
        color="d-blue"
        className="rewe"
        text="- Die Präexpositionsprophylaxe (PrEP) schützt vor HIV. Nun ist das Medikament aufgrund von Lieferengpässen nur noch schwer zu bekommen. Informationen dazu was PrEP-Nutzer*innen jetzt tun können findet ihr im Link."
      />
      <hr className="padtopbottom1rem" />
      <RowColFull>
        <h2 className="d-blue center">Safe the date: CSD Termine-2023</h2>
      </RowColFull>
      <RowColFull className="padbottom2rem">
        <p className="center">
          5. CSD Kelheim: Samstag, 01. Juni 2024
          <br />
          5. CSD Straubing: Samstag, 27. Juli 2024
          <br />
          6. CSD Landshut: Samstag, 28. Sept. 2024
        </p>
      </RowColFull>
      <RowColFull>
        <PicLink
          link="https://www.rewe-group.com/de/unternehmen/unternehmenskultur/diversity/"
          src={Rewe}
          className="sponsor"
          alt="Rewe Logo verlinkt zu Rewe diversity"
        />
      </RowColFull>
      <hr className="padtopbottom2rem" />
      <Row>
        <Col md>
          {/* <PicLink
            link="https://m.facebook.com/events/938920410807697?view=permalink&id=977734793592925&sfnsn=scwspwa"
            className="csd"
            src={Kelheim}
            alt="CSD 2023 in Kelheim"
          /> */}
          <img className="csd" src={Kelheim} alt="CSD 2023 in Kelheim" />
        </Col>
        <Col md>
          {/* <a href="https://www.facebook.com/events/2579937998817983/" target="_blank"> */}
          <img className="csd" src={Straubing} alt="CSD 2023 in Straubing" />
        </Col>
        <Col md>
          {/* <a href="https://www.facebook.com/events/1238656666580994" target="_blank"> */}
          <img className="csd" src={Landshut} alt="CSD 2023 in Landshut" />
        </Col>
      </Row>
      <Row className="padtopbottom1rem">
        <Col md>
          <p>
            Du hast Lust bei unseren CSDs oder bei der Vorbereitung und
            Organisation mithelfen? Wir freuen uns über deine Unterstützung!
            <br />
            Ob als Ordner*in, beim Auf- bzw. Abbau, beim Getränkeausschank, etc.
            oder einfach jemand, der uns bei der Organisation helfen möchte:{" "}
            <a className="d-blue" href="mailto:kontakt@queer-niederbayern.de">
              Schreib uns gern!
            </a>
          </p>
        </Col>
        <Col md>
          <p>
            Helfende Hände finden wir super. Aber eine Veranstaltung wie der
            Christopher-Street-Day finanziert sich allerdings nicht von selbst.
            Deshalb freuen wir uns über jede Spende!{" "}
            <strong className="d-blue">Als Überweisung</strong> auf unser Konto:
          </p>
          <p className="notjustified">
            Sparkasse Landshut Kontoinhaber: Queer in Niederbayern e. V.
            <br />
            IBAN: DE09 7435 0000 0020 9682 56
            <br />
            BIC: BYLADEM1LAH
          </p>
        </Col>
        <Col md>
          <p>
            Du bist oder kennst mögliche Teilnehmer*innen, Künstler*innen oder
            oder Performer*innen für unsere Christopher Street Days?
            <br />
            <a
              className="d-blue"
              href="./"
              target="_blank"
              onClick={() => window.open(Anmeldung)}
            >
              Formular herunterladen
            </a>
            , ausfüllen und per E-Mail an{" "}
            <a className="d-blue" href="mailto:infostand@queer-niederbayern.de">
              infostand@queer-niederbayern.de
            </a>{" "}
            senden.
            <br />
            Wir freuen uns darauf
          </p>
        </Col>
      </Row>
      <RowColFull className="padtopbottom1rem">
        <p className="center">
          Alle Spenden sind von der Steuer absetzbar – <br />
          schreibt für eine Spendenquittung eine Email an{" "}
          <a className="d-blue" href="mailto:finanzen@queer-niederbayern.de">
            finanzen@queer-niederbayern.de
          </a>
          .
        </p>
        <p className="small">
          Für die steuerliche Absetzbarkeit von Spenden bis 300 Euro genügt euer
          Kontoauszug mit dem Verwendungszweck "Spende" und Queer in
          Niederbayern e. V. als Empfänger. Ab 300 Euro erhaltet ihr
          unaufgefodert eine förmliche und anerkannte Zuwendungsbescheinigung.
          Wer für kleiner Spenden auch eine Spendenbescheinigung wünscht,
          schreibt uns bitte kurz eine Mail. Vielen Dank.
        </p>
      </RowColFull>
    </MainPages>
  );
}
